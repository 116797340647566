/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

.navbar {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  padding-right: 1em;
  padding-top: 1em;
  transition: all .3s ease-in;
}

.navbar.active {
  background: #050445 !important;
  padding-bottom: 0px;
}

.navbar-toggler-icon {
  background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/togglericon.webp") !important;
}

.brandTagLineContainerDesktop {
  position: relative;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.brandTagLine {
  font-size: 1.25rem;
  background-color: #0d6efd;
  color: #fff;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  margin-bottom: 0px;
  border-color: #0d6efd;
}

.brandTagLine:hover {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

@media (min-width: 992px) {
  .seperator {
    color: #fff;
    font-weight: 600;
    margin-top: 0rem;
  }

  .seperator {
    max-width: 100%;
  }

  .contactbutt {
    color: #FFF !important;
    border-radius: 5px;
  }

  .contactbutt:hover {
    color: #FFF !important;
  }

  .contactbutt a,
  .contactbutt button,
  .contactbutt button:hover,
  .contactbutt button:active,
  .contactbutt button:focus,
  .contactbutt button:active {
    color: #FFF !important;
    background-color: #EE6124;
    padding-left: 1.5rem !important;
    padding-right: 2rem !important;
  }

  .brandTagLineContainerMobile {
    display: none;
  }

  .nav_temp {
    margin: 0px 2rem !important;
  }

  .navbar {
    padding: unset !important;
  }

  .MobileButtomTeam {
    display: none;
  }
}

.Nav a {
  cursor: pointer;
}

.navbar-dark .nav-link a {
  cursor: pointer !important;
}

.getlink {
  cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1044.7px) {
  .brandTagLine {
    padding: 1px !important;
  }
}

@media only screen and (min-width: 1045px) and (max-width: 1150.7px) {
  .brandTagLine {
    padding: 5px !important;
  }
}

@media (max-width: 1100.7px) {
  .brandTagLine {
    font-size: 1rem;
  }
}

@media (max-width: 991.7px) {
  .navbarTransition {
    transform: translateY(-100%);
    transition: all .3s ease-in;
  }
  .mobilecontact {
    background-color: #EE6124 !important;
  }
  .brandTagLineContainerDesktop {
    display: none;
  }

  .brandTagLineContainerMobile {
    text-align: center;
    position: absolute;
    z-index: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    top: 9%;
  }

  .desktoTeamBut {
    display: none;
  }

  .seperator {
    display: none;
  }

  .MobileButtomTeam {
    margin-top: 5rem;
    text-align: center;
  }

  .navbar-toggler {
    border: none !important;
    background-color: #050445 !important;
  }

  .navbar-nav {
    padding: 0.5rem 1.5rem;
    background-color: #050445;
  }

  .navbar-dark .navbar-toggler {
    background-color: unset !important;
  }

  .navbar-collapse,
  .navbar.active {
    /* background: rgba(8, 63, 136, 0.9) !important; */
    z-index: 10;
  }
}

.dropdown-toggle::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAdklEQVQ4je3QsQ6CQBCEYRpiQigI8aV5GSpKrqG18Y0+G43H5RaxNHHa2flnd5vmr50wojvwO4yROeCGDX0QXnHHtQaYvJXyTZ7hlPlTDdBizoY29FnzSwsu0RklJBXNM9roRxHkc/MJyPlwBfJ9uIAc3/z7egC+KuGQHgx9zAAAAABJRU5ErkJggg==);
  border: none;
  vertical-align: -0.09em;
  margin-top: 0px;
  display: inline-block;
  margin-left: 0.255em;
}

.homepageNav {
  z-index: 1200;
  box-shadow: none;
  font-size: 17px;
  background-color: white !important;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  justify-content: space-between !important;
  padding: 0.5rem 1rem !important;
}

.homepageNav>div>div>div a {
  /* color:#0F5B93  !important; */
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.homepageNav>div a {
  /* color:#0F5B93  !important; */
  color: black !important;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.homepageNav>div>div>div>div>a {
  color: rgb(53, 53, 56) !important;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.homepageNav>div>div>div a::after {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA30lEQVQ4je2OPw/BQBjGn2t6ldS/EpZ+CYvVwmYTacRmOpvN4EswGc43MAkiWCy+j5D0hp6ktbS00YsYJZ7pcu/7+z0v8E8iBWdathk3VXObcbPgTMvxPy16WIOZRSg9ukIeqs48lwYL4W2IQU/5Pq+8CYK7PgFBnQANSf11/BKbcVO4cguQFoCapslxNCPPCsZpUcglgA4ABMA5I7U2Lem+EN4mhEGA3fUSdLEfeUlBigQBTmFNM9xY3bJGD4vh/f0CleS1mGhWC1IkKlgtiEkIYKjgz2GcgnH6PfhTeQBLbFnG/k9TJgAAAABJRU5ErkJggg==");
  vertical-align: -0.09em;
  border: none;
  margin-top: 0px;
}

.homepageNav>div>div>div>div a::after {
  content: "";
  vertical-align: middle;
  border: none;
  margin-top: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-dark .navbar-nav .nav-link {
    margin: 0px 1rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1195px) {
  .main_logo {
    margin-left: unset !important;
  }
}

.main_logo {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.trust_logo {
  height: 50px;
  width: 55px;
}

.normalNav {
  background: linear-gradient(240deg,
      rgba(38, 170, 225, 1) 0%,
      rgba(3, 67, 144, 1) 100%) !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  z-index: 1200;
  font-size: 17px;
}

.normalNav>div>div>div a {
  color: white !important;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.normalNav>div a {
  color: white !important;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.normalNav>div>div>div>div>a {
  color: rgb(53, 53, 56) !important;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.normalNav>div>div>div a::after {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAdklEQVQ4je3QsQ6CQBCEYRpiQigI8aV5GSpKrqG18Y0+G43H5RaxNHHa2flnd5vmr50wojvwO4yROeCGDX0QXnHHtQaYvJXyTZ7hlPlTDdBizoY29FnzSwsu0RklJBXNM9roRxHkc/MJyPlwBfJ9uIAc3/z7egC+KuGQHgx9zAAAAABJRU5ErkJggg==");
  vertical-align: -0.05em;
  border: none;
  margin-top: 0px;
}

.normalNav>div>div>div>div a::after {
  content: "";
  vertical-align: middle;
  border: none;
  margin-top: 0px;
}

.buttonLogin>button {
  background-color: transparent;
  min-width: 10vw;
  box-shadow: none;
  color: white;
  border: transparent;
  border-radius: 25px;
}

.buttonLogin>button:hover {
  background: #fff;
  color: #00aae4 !important;
}

.homepageButtonApply {
  background-color: #00aae4 !important;
}

.homepageButtonApply a {
  text-decoration: none;
  color: white !important;
}

.homepageButtonApply:hover {
  background-color: #00aae4 !important;
  color: white !important;
}

.buttonApply {
  margin-top: -6px;
  border: 1px solid #fff;
  min-width: 12vw;
  background: #fff;
  color: #00aae4;
  border-radius: 20px;
  min-width: 10vw;
  box-shadow: none;
}

.buttonApply :hover {
  border: 1px solid #fff;
  background: #fff;
  color: #0481b5;
}

.buttonApply :focus {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.buttonApply :active {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.buttonApply:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #0481b5;
}

.buttonLogout {
  margin-top: -6px;
  border: 1px solid #fff;
  min-width: 12vw;
  background: #fff;
  color: #0481b5;
  border-radius: 20px;
  min-width: 10vw;
  box-shadow: none;
}

.buttonLogout :focus {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.buttonLogout :active {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.buttonLogout:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #0481b5;
}

.buttonStart>button:hover {
  background-color: #fff !important;
  color: #0481b5;
  border: 1px solid #fff;
}

.buttonStart>button {
  margin-top: 0px;
  border: 1px solid #fff;
  min-width: 12vw;
  background: #fff;
  color: #0481b5;
  border-radius: 20px;
}

.buttonStart>button:focus {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.buttonStart>button:active {
  background: #fff;
  color: #0481b5;
  box-shadow: none;
}

.external {
  color: black;
  background-color: transparent;
  border: 1px solid transparent;
}

.external:hover {
  border-bottom: 1px solid #083f88;
}

.buttonRes {
  margin-top: -6px;
  background: transparent;
  border: transparent;
  border-radius: 25px;
  min-width: auto;
  box-shadow: none;
  margin-left: -1vw;
}

.itemExt:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa !important;
}

.buttonRes:focus {
  background-color: transparent;
}

.buttonRes:hover {
  background: #fff;
  color: #0481b5;
}

.sub_link {
  text-decoration: none;
  color: #16181b;
  padding: 10px;
  width: 100%;
}

.sub_link:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

@media (max-width: 990px) {

  .nav_temp>div,
  .nav_temp>a {
    width: fit-content;
  }
}

@media (max-width: 767px) {
  .homepageNav {
    background: linear-gradient(240deg,
        rgba(38, 170, 225, 1) 0%,
        rgba(3, 67, 144, 1) 100%) !important;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 0;
    transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    z-index: 1200;
  }

  .homepageNav>div>div>div a {
    color: white !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  .buttonApply>button {
    margin-left: -5px;
  }

  .buttonStart>button {
    margin-left: -5px;
  }

  .main_logo {
    width: 150px;
    height: auto;
  }

  .trust_logo {
    width: 45px;
    height: 40px;
  }

  .homepageNav>div a {
    color: white !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  .homepageNav>div>div>div a::after {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAdklEQVQ4je3QsQ6CQBCEYRpiQigI8aV5GSpKrqG18Y0+G43H5RaxNHHa2flnd5vmr50wojvwO4yROeCGDX0QXnHHtQaYvJXyTZ7hlPlTDdBizoY29FnzSwsu0RklJBXNM9roRxHkc/MJyPlwBfJ9uIAc3/z7egC+KuGQHgx9zAAAAABJRU5ErkJggg==");
    vertical-align: -0.05em;
    border: none;
    margin-top: 0px;
  }
}

@media (max-width: 320px) {
  .main_logo {
    width: 132px;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) {
  .homepageNav>div a {
    color: rgb(53, 53, 56) !important;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    font-size: 13px !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: right;
  }
}

@media (max-width: 991px) {
  .navbar {
    padding-top: unset !important;
  }
  .userway-div{
    display: none;
  }
}

.dropdown-item {
  color: #083f88;
}

.dropdown-item:hover {
  background: rgba(0, 170, 228, 0.3) !important;
  color: #083f88;
  border-radius: 5px;
  border-bottom: unset !important;
}

.nav-link {
  font-family: "Poppins" !important;
  font-weight: 700;
}
@media (min-width: 321px) and (max-width: 374.9px) {
  .main_logo {
    margin-left: 0.2rem;
    margin-top: 0.25rem;
  }
}
@media (min-width: 375px) and (max-width: 767.9px) {
  .main_logo {
    margin-left: 0.2rem;
    margin-top: 0.25rem;
  }
}

